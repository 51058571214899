import React, { useContext } from 'react'
import './section4.scss'
import banner from '../../images/services-images.png'
import { FormattedMessage, IntlProvider } from 'react-intl';
import deMessages from '../de.json';
import enMessages from '../en.json';
import { TranslateContext } from '../../context/TranslateContext';


const messages = {
    'de': deMessages,
    'en': enMessages
};


const Section4 = () => {
    const { locale } = useContext(TranslateContext);
    return (
        <IntlProvider locale={locale} messages={messages[locale]}>

            <div className='section4'>
                <div className="container-section4">
                    <div className="images-section4" data-aos="fade-up">
                        <img src={banner} alt="" />
                    </div>
                    <div className="text" data-aos="fade-up">
                        <span><FormattedMessage id='section4_awarded' /></span>
                        <h1><FormattedMessage id='section4_awards' /></h1>
                        <p>
                            <FormattedMessage id='section4_description' />
                        </p>

                        <span style={{ color: 'white', marginTop: '20px', letterSpacing: '2px' }} >- <FormattedMessage id='section4_services' /></span>
                    </div>


                </div>
            </div>
        </IntlProvider>
    )
}

export default Section4