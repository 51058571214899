import React, { useState, useEffect, useContext } from 'react';
import './services4.scss'
import { HiOutlineStar, HiStar } from 'react-icons/hi'
import { AiOutlineStar } from 'react-icons/ai'

import { TranslateContext } from '../../context/TranslateContext';

import { FormattedMessage, IntlProvider } from 'react-intl';
import deMessages from '../de.json';
import enMessages from '../en.json';


const messages = {
    'de': deMessages,
    'en': enMessages
}



const Services4 = () => {

    const [ribaAwards, setRibaAwards] = useState(0);
    const [clients, setClients] = useState(0);
    const [prototypes, setPrototypes] = useState(0);

    const { locale } = useContext(TranslateContext);

    useEffect(() => {
        const incrementRibaAwards = setInterval(() => {
            if (ribaAwards === 51) {
                clearInterval(incrementRibaAwards);
                return;
            }
            setRibaAwards(ribaAwards + 1);
        }, 40);

        const incrementClients = setInterval(() => {
            if (clients === 240) {
                clearInterval(incrementClients);
                return;
            }
            setClients(clients + 1);
        }, 20);

        const incrementPrototypes = setInterval(() => {
            if (prototypes === 301) {
                clearInterval(incrementPrototypes);
                return;
            }
            setPrototypes(prototypes + 1);
        }, 20);

        return () => {
            clearInterval(incrementRibaAwards);
            clearInterval(incrementClients);
            clearInterval(incrementPrototypes);
        };
    }, [ribaAwards, clients, prototypes]);





    return (
        <IntlProvider locale={locale} messages={messages[locale]}>

            <div className='services4'>
                <div className="services4-container">
                    <div className="text">
                        <span data-aos="fade-up" style={{ letterSpacing: '5px', color: 'rgb(180, 71, 71)', fontWeight: 'bolder' }}>.03</span>
                        <h1 data-aos="fade-up">- <FormattedMessage id='section4.h1' /></h1>
                        <p data-aos="fade-up">
                            <FormattedMessage id='section4.p' />
                        </p>
                        <div className="stars" data-aos="fade-up">
                            <HiStar size={20} color='yellow' />
                            <HiStar size={20} color='yellow' />
                            <HiStar size={20} color='yellow' />
                            <HiStar size={20} color='yellow' />
                            <AiOutlineStar size={20} color='yellow' />
                        </div>
                    </div>
                    <div className="counter" data-aos="fade-up">
                        {/* <div className="counter-1">
                        <span>{ribaAwards}</span>
                        <h1>RIBA AWARDS</h1>
                        <div className="horizontal-line"></div>

                    </div> */}
                        <div className="counter-1" data-aos="fade-up">
                            <span>{clients}</span>
                            <h1><FormattedMessage id='section4.counter1' /></h1>
                            <div className="horizontal-line"></div>

                        </div>
                        <div className="counter-1" data-aos="fade-up">
                            <span>{prototypes}</span>
                            <h1><FormattedMessage id='section4.counter2' /></h1>
                            <div className="horizontal-line"></div>

                        </div>
                    </div>
                </div>
            </div>
        </IntlProvider>
    )
}

export default Services4