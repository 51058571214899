import React, { useEffect } from 'react'
import Contact1 from '../../component/section1-contact/Contact1'
import Contact2 from '../../component/section2-contact/Contact2'
import Contact3 from '../../component/section3-contact/Contact3'
import Contact4 from '../../component/section4-contact/Contact4'

const Contact = () => {
    useEffect(() => {
        document.title = "Kontakt - Malerbetrieb Uelzen - André Mehmel & Fidan Iseni GbR";
    }, []);
    return (
        <div>
            <Contact1 />
            <Contact2 />
            <Contact3 />
            <Contact4 />
        </div>
    )
}

export default Contact