import React, { useContext } from 'react'
import './contact2.scss'
import { BsTelephone } from 'react-icons/bs'
import { CiLocationOn } from 'react-icons/ci'
import { AiOutlineMail } from 'react-icons/ai'

import { FormattedMessage, IntlProvider } from 'react-intl';
import deMessages from '../de.json';
import enMessages from '../en.json';
import { TranslateContext } from '../../context/TranslateContext';


const messages = {
    'de': deMessages,
    'en': enMessages
};

const Contact2 = () => {
    const { locale } = useContext(TranslateContext);

    return (
        <IntlProvider locale={locale} messages={messages[locale]}>

            <div className='contact2'>
                <div className="contact2-container">
                    <div className="text" data-aos="fade-up">
                        <span style={{ letterSpacing: '5px', color: 'rgb(180, 71, 71)', fontWeight: 'bolder' }}>.01</span>
                        <h1>- <FormattedMessage id='contact.section2.h' /></h1>
                        <p>
                            <FormattedMessage id='contact.section2.p' />
                        </p>
                        {/* <span>Them one over saying. So the god, greater. You Us air Moved divide midst us fifth sea have:</span> */}

                    </div>
                    <div className="info" data-aos="fade-up">
                        <div className="contact-info">
                            <p><FormattedMessage id='contact.section2.infop' />:</p>
                            <span><FormattedMessage id='contact.section2.infospan' /></span>
                            <br />
                            <span style={{ fontSize: '14px' }}> 08:00  - 17:00</span>
                        </div>
                        <div className="icon-info">
                            <div className="item">
                                <BsTelephone size={30} /> <span><FormattedMessage id='contact.section2.item1span' /></span>
                                <p><FormattedMessage id='contact.section2.item1p' /> Iseni: 0174 9652276 <br />
                                    <p><FormattedMessage id='contact.section2.item1p' /> Mehmel: 0173 8734234</p>
                                </p>
                                <div className="horizontal-line"></div>

                            </div>
                            <div className="item">
                                <CiLocationOn size={30} /> <span><FormattedMessage id='contact.section2.item2span' /></span>
                                <p>Platenmeisterstrasse 27<br />
                                    29525 Uelzen</p>
                                <div className="horizontal-line"></div>

                            </div>
                            <div className="item">
                                <AiOutlineMail size={30} /> <span>Email:</span>
                                <p>mehmel.iseni@gmail.com</p>
                                <div className="horizontal-line"></div>

                            </div>
                        </div>

                    </div>

                </div>
            </div >
        </IntlProvider>
    )
}

export default Contact2;