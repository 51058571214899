import React, { useState, useContext } from 'react';
import { FormattedMessage, IntlProvider } from 'react-intl';
import deMessages from '../de.json';
import enMessages from '../en.json';
import './impresum1.scss'
import img1 from '../../images/pexels-photo-1797418.webp'
import logo from '../../images/bildmarke_94x78px.png'
import { TranslateContext } from '../../context/TranslateContext';
import { Link } from 'react-router-dom';


const messages = {
    'de': deMessages,
    'en': enMessages
};

const Impresum1 = () => {
    const { locale } = useContext(TranslateContext);


    return (
        <IntlProvider locale={locale} messages={messages[locale]}>
            <div className='section1-impresum'>
                <div className="images-container">
                    <div className="images-impresum">
                        <img src={img1} alt="" />
                    </div>
                    <div className="container-section-impresum">

                        <div className="logo" data-aos="fade-up">

                            <img src={logo} alt="" />

                        </div>

                        <div className="vertical-line1"></div>
                        <div className="text">
                            <h1><FormattedMessage id='impresum.section1h' /> </h1>
                            {/* <p>wir sorgen fur ihren perfekten anstrich</p> */}
                        </div>
                        <div className="container-line">
                            <div className="vertical-line"></div>
                            <div className="horizontal-line"></div>
                        </div>
                    </div>
                </div>
            </div>
        </IntlProvider>
    );
};

export default Impresum1;
