import React, { useContext } from 'react'
import './impresum2.scss'
import { FormattedMessage, IntlProvider } from 'react-intl';
import deMessages from '../de.json';
import enMessages from '../en.json';
import { TranslateContext } from '../../context/TranslateContext';


const messages = {
    'de': deMessages,
    'en': enMessages
};


const Impresum2 = () => {
    const { locale } = useContext(TranslateContext);
    window.bingMapsTermsUrl = "https://www.microsoft.com/en-us/maps/product/terms";
    window.bingMapsPrivacyUrl = "https://privacy.microsoft.com/de-de/privacystatement/";
    const termsLink = 'https://www.microsoft.com/en-us/maps/product/terms';

    return (
        <IntlProvider locale={locale} messages={messages[locale]}>
            <div className='impresum2'>
                <div className="impresum2-container">
                    <div className="impresum2-item">
                        <div className="impresum-box">
                            <h1><FormattedMessage id='impresum.section2.boxh' /></h1>
                            <p><FormattedMessage id='impresum.section2.boxp' /></p>
                            <p>Platenmeisterstraße 27</p>
                            <p style={{ paddingBottom: '1rem' }}>29525 Uelzen</p>
                            <span>Phone: 0173 8734234 <br />
                                Email: mehmel.iseni@gmail.com</span>
                        </div>

                        <div className="impresum-box1">
                            <h1><FormattedMessage id='impresum.section2.box1h' /></h1>
                            <p style={{ paddingBottom: '1rem' }}><FormattedMessage id='impresum.section2.box1p' /></p>
                            <p style={{ fontSize: '1.2rem', fontWeight: "bold" }}><FormattedMessage id='impresum.section2.box1p1' /></p>

                            <span>Andre Mehmel
                                <br />
                                Fidan Iseni</span>
                        </div>

                        <div className="impresum-box2">
                            <h1><FormattedMessage id='impresum.section2.box2h' /></h1>
                            <p><FormattedMessage id='impresum.section2.box2p' /></p>

                        </div>

                        <div className="impresum-box3">
                            <h1><FormattedMessage id='impresum.section2.box3h' /></h1>
                            <p style={{ paddingBottom: "0.5rem" }}><FormattedMessage id='impresum.section2.box3p' /></p>

                            <ul>
                                <li><FormattedMessage id='impresum.section2.box3li1' /></li>
                                <li><FormattedMessage id='impresum.section2.box3li2' /></li>
                                <li><FormattedMessage id='impresum.section2.box3li3' /></li>
                                <li><FormattedMessage id='impresum.section2.box3li4' /></li>
                                <li><FormattedMessage id='impresum.section2.box3li5' /></li>
                                <li><FormattedMessage id='impresum.section2.box3li6' /></li>
                                <li><FormattedMessage id='impresum.section2.box3li7' /></li>
                                <li><FormattedMessage id='impresum.section2.box3li8' /></li>
                                <li><FormattedMessage id='impresum.section2.box3li9' /></li>
                                <li><FormattedMessage id='impresum.section2.box3li10' /></li>
                            </ul>
                        </div>

                        <div className="impresum-box4">
                            <h1><FormattedMessage id='impresum.section2.box4h' /></h1>
                            <p><FormattedMessage id='impresum.section2.box4p' /></p>
                        </div>

                        <div className="impresum-box5">
                            <h1>
                                <FormattedMessage id='impresum.section2.box5h' />
                            </h1>
                            <p><FormattedMessage id='impresum.section2.box5p' /></p>
                        </div>

                        <div className="impresum-box6">
                            <h1><FormattedMessage id='impresum.section2.box6h' /></h1>
                            <p><FormattedMessage id='impresum.section2.box6p' /></p>
                        </div>


                        <div className="impresum-box7">
                            <h1><FormattedMessage id='impresum.section2.box7h' /></h1>
                            <p style={{ paddingBottom: "0.5rem" }}><FormattedMessage id='impresum.section2.box7p' /></p>
                            <ul style={{ paddingBottom: "0.5rem" }}>
                                <li><FormattedMessage id='impresum.section2.box7l' /></li>
                                <li><FormattedMessage id='impresum.section2.box7l1' /></li>
                                <li><FormattedMessage id='impresum.section2.box7l2' />,</li>
                                <li><FormattedMessage id='impresum.section2.box7l3' /></li>
                                <li><FormattedMessage id='impresum.section2.box7l4' /></li>

                            </ul>
                            <p><FormattedMessage id='impresum.section2.box7p2' /></p>
                        </div>


                        <div className="impresum-box8">
                            <h1><FormattedMessage id='impresum.section2.box8h' /></h1>
                            <p>
                                <FormattedMessage id='impresum.section2.box8p' />
                            </p>
                        </div>

                        <div className="impresum-box9">
                            <h1><FormattedMessage id='impresum.section2.box9h' /></h1>
                            <p>
                                <FormattedMessage id='impresum.section2.box9p' />
                            </p>
                        </div>

                        <div className="impresum-box10">
                            <h1><FormattedMessage id='impresum.section2.box10h' /></h1>
                            <p>
                                <FormattedMessage id='impresum.section2.box10p' />
                            </p>

                            <p style={{ paddingBottom: "0.5rem" }}>1.<FormattedMessage id='impresum.section2.box10p1' />
                            </p>
                            <ul style={{ paddingBottom: "0.5rem" }}>
                                <li><FormattedMessage id='impresum.section2.box10l1' /></li>
                                <li><FormattedMessage id='impresum.section2.box10l2' /></li>
                            </ul>
                            <p >2.<FormattedMessage id='impresum.section2.box10p2' />
                            </p>
                            <p>3.<FormattedMessage id='impresum.section2.box10p3' /></p>
                            <p>4.<FormattedMessage id='impresum.section2.box10p4' />
                            </p>
                        </div>

                        <div className="impresum-box11">
                            <h1><FormattedMessage id='impresum.section2.box11h' /></h1>
                            <p>
                                <FormattedMessage id='impresum.section2.box11p' />
                            </p>
                        </div>

                        <div className="impresum-box12">
                            <h1><FormattedMessage id='impresum.section2.box12h' /></h1>
                            <p>
                                <FormattedMessage id='impresum.section2.box12p' />
                            </p>
                        </div>

                        <div className="impresum-box13">
                            <h1><FormattedMessage id='impresum.section2.box13h' /></h1>
                            <p>
                                <FormattedMessage id='impresum.section2.box13p' />
                            </p>
                        </div>


                        <div className="impresum-box14">
                            <h1><FormattedMessage id='impresum.section2.box14h' /></h1>
                            <p>
                                <FormattedMessage id='impresum.section2.box14p' />
                            </p>
                        </div>

                        <div className="impresum-box15">
                            <h1><FormattedMessage id='impresum.section2.box15h' /></h1>
                            <p>
                                <FormattedMessage id='impresum.section2.box15p' />
                            </p>
                        </div>

                        <div className="impresum-box18">
                            <p>
                                <FormattedMessage id='impresum.section2.box18h' />
                            </p>
                        </div>

                        <div className="impresum-box18">
                            <p>
                                <FormattedMessage id='impresum.section2.box19h' /> <a href=" https://tools.google.com/dlpage/gaoptout."> https://tools.google.com/dlpage/gaoptout.</a>
                            </p>
                            <p><FormattedMessage id='impresum.section2.box19span' /></p>
                        </div>

                        <div className="impresum-box16">
                            <h1><FormattedMessage id='impresum.section2.box16h' /></h1>
                            <p>
                                <FormattedMessage id='impresum.section2.box16p' />
                                (<a href="https://www.microsoft.com/en-us/maps/product/terms">https://www.microsoft.com/en-us/maps/product/terms</a>).
                                <FormattedMessage id='impresum.section2.box16span1' />
                                <a href="https://privacy.microsoft.com/de-de/privacystatement/">https://privacy.microsoft.com/de-de/privacystatement</a>,
                                <FormattedMessage id='impresum.section2.box16span2' />
                            </p>
                        </div>

                        <div className="impresum-box17">
                            <h3><FormattedMessage id='impresum.section2.box17h' /></h3>
                            <p>
                                <FormattedMessage id='impresum.section2.box17p' />
                            </p>
                        </div>

                    </div>
                </div>
            </div>
        </IntlProvider>
    )
}

export default Impresum2