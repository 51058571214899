import React, { useContext } from 'react';
import { createBrowserRouter, RouterProvider, Route, Outlet, Navigate } from 'react-router-dom';
import Services from './pages/services/Services.jsx';
import Portfolio from './pages/portfolio/Portfolio.jsx';
import Home from './pages/home/Home.jsx';
import Footer from './component/footer/Footer.jsx';
import Navbar from './component/navbar/Navbar.jsx';
import About from './pages/about/About';
import Contact from './pages/contact/Contact';
import './style.scss';
import TranslateProvider, { TranslateContext } from './context/TranslateContext.js';
import Impressum from './pages/privacypolicy/Impressum.jsx';

function App() {
  const { locale, messages, changeLocale } = useContext(TranslateContext);

  const Layout = () => {
    return (
      <div>
        <Navbar />
        <div>
          <div><Outlet /></div>
        </div>
        <Footer />
      </div>
    );
  };

  const router = createBrowserRouter([
    {
      path: '/',
      element: <Layout />,
      children: [
        {
          path: '/',
          element: <Home />,
        },
        {
          path: '/about',
          element: <About />,
        },
        {
          path: '/portfolio',
          element: <Portfolio />,
        },
        {
          path: '/services',
          element: <Services />,
        },
        {
          path: '/contact',
          element: <Contact />,
        },
        {
          path: '/impressum',
          element: <Impressum />,
        },
      ],
    },
  ]);

  const handleLanguageChange = (event) => {
    changeLocale(event.target.value);
  };

  return (
    <div>

      <RouterProvider router={router} />
    </div>
  );
}

const AppWithTranslation = () => {
  return (
    <TranslateProvider>
      <App />
    </TranslateProvider>
  );
};

export default AppWithTranslation;
