import './section1.scss'
import React, { useRef, useState, useContext } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import swip1 from '../../images/pexels-photo-1797418.webp'
import swip2 from '../../images/swiper.png'
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "swiper/css/effect-cube";
import logo from '../../images/bildmarke_94x78px.png'
import { FormattedMessage, IntlProvider } from 'react-intl';
import { Link } from 'react-router-dom'
import { TranslateContext } from '../../context/TranslateContext';
// import required modules
import { EffectFade, Pagination, Autoplay } from "swiper";
import deMessages from '../de.json';
import enMessages from '../en.json';


const messages = {
    'de': deMessages,
    'en': enMessages
};




const Section1 = () => {

    const handleLanguageChange = (event, changeLocale) => {
        changeLocale(event.target.value);
    };

    const { locale, changeLocale, messages } = useContext(TranslateContext);





    return (
        <IntlProvider locale={locale} messages={messages[locale]}>

            <div>
                <Swiper
                    autoplay={{
                        delay: 4500,
                        disableOnInteraction: false,
                    }}
                    loop={true}

                    effect={"fade"}
                    navigation={true}
                    // pagination={{
                    //     clickable: true,
                    // }}
                    modules={[Autoplay, Pagination, EffectFade]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <div className="img-swiper">
                            <img src={swip1} alt="" />

                        </div>
                        <div className="container-swiper">

                            <div className="logo">
                                <Link to='/'>
                                    <img src={logo} alt="" />
                                </Link>
                            </div>
                            <div className="vertical-line1"></div>

                            <div className="text" data-aos="fade-up">
                                <h1>{messages.swiper1h}</h1>
                                <p>{messages.swiper1p}</p>
                            </div>

                            <div className="container-line">
                                <div className="vertical-line"></div>
                                <div className="horizontal-line"></div>
                            </div>



                        </div>



                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="img-swiper">
                            <img src={swip2} alt="" />

                        </div>
                        <div className="container-swiper">

                            <div className="logo">
                                <Link to='/'>
                                    <img src={logo} alt="" />
                                </Link>

                            </div>
                            <div className="vertical-line1"></div>

                            <div className="text" data-aos="fade-up">
                                <h1>{messages.swiper2h}</h1>
                                {/* <p>MIT UNS WIRD RENOVIERUNG LEICHT GEMACHT</p> */}
                            </div>

                            <div className="container-line">
                                <div className="vertical-line"></div>
                                <div className="horizontal-line"></div>
                            </div>



                        </div>


                    </SwiperSlide>


                </Swiper >
            </div >
        </IntlProvider>
    )
}

export default Section1