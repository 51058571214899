import React, { useContext } from 'react'
import './section2.scss'
import { TranslateContext } from '../../context/TranslateContext';
import { FormattedMessage, IntlProvider } from 'react-intl';
import deMessages from '../de.json';
import enMessages from '../en.json';


const messages = {
    'de': deMessages,
    'en': enMessages
};


const Section2 = () => {
    const { locale } = useContext(TranslateContext);

    return (
        <IntlProvider locale={locale} messages={messages[locale]}>

            <div className='section2-about'>
                <div className="section2-container">
                    <div className="text" data-aos="fade-up">
                        <span style={{ letterSpacing: '5px', color: 'rgb(180, 71, 71)', fontWeight: 'bolder' }}>.01</span>
                        <h1>- <FormattedMessage id='aboutsection2.h' /></h1>
                        <p><FormattedMessage id='aboutsection2.p' /></p>

                    </div>
                    <div className="story-section2" >
                        <div className="item" data-aos="fade-up">
                            <div className="year">
                                <span>2016</span>
                            </div>

                            <div className="box">
                                {/* <h2>WE ARE 10 PEOPLE BIGGER NOW</h2> */}
                                <p><FormattedMessage id='aboutsection2.box1p1' /></p>

                            </div>
                            <div className="box">
                                {/* <h3>- YEAR IN REVIEW</h3> */}
                                <p><FormattedMessage id='aboutsection2.box1p2' /></p>
                            </div>
                        </div>
                        <div className="item" data-aos="fade-up">
                            <div className="year">
                                <span>2020</span>
                            </div>

                            <div className="box">
                                {/* <h2>WE ARE 10 PEOPLE BIGGER NOW</h2> */}
                                <p><FormattedMessage id='aboutsection2.box2p1' /></p>

                            </div>
                            <div className="box">
                                {/* <h3>- YEAR IN REVIEW</h3> */}
                                <p><FormattedMessage id='aboutsection2.box2p2' /></p>
                            </div>
                        </div>
                        <div className="item" data-aos="fade-up">
                            <div className="year">
                                <span>2023</span>
                            </div>

                            <div className="box">
                                {/* <h2>WE ARE 10 PEOPLE BIGGER NOW</h2> */}
                                <p><FormattedMessage id='aboutsection2.box3p1' /></p>
                            </div>
                            <div className="box">
                                {/* <h3>- YEAR IN REVIEW</h3> */}
                                <p> <FormattedMessage id='aboutsection2.box3p2' /></p>
                            </div>
                        </div>
                    </div>
                </div>

            </div >
        </IntlProvider>
    )
}

export default Section2