import React, { useContext } from 'react';
import './section3about.scss';
import { RiGroupLine } from 'react-icons/ri';
import { BsPen } from 'react-icons/bs';
import { GiFlexibleLamp } from 'react-icons/gi';
import { FormattedMessage, IntlProvider } from 'react-intl';
import deMessages from '../de.json';
import enMessages from '../en.json';
import { TranslateContext } from '../../context/TranslateContext';


const messages = {
    'de': deMessages,
    'en': enMessages
};

const Section3 = () => {
    const { locale } = useContext(TranslateContext);

    return (
        <IntlProvider locale={locale} messages={messages[locale]}>

            <div className='section3-about'>
                <div className="section3-container">
                    <div className="text-section3" data-aos="fade-up">
                        <span style={{ letterSpacing: '5px', color: 'rgb(180, 71, 71)', fontWeight: 'bolder' }}>.02</span>
                        <h1>- <FormattedMessage id='section3.h1' /></h1>
                        <p><FormattedMessage id='section3.p1' /></p>
                    </div>
                    <div className="box-section3">
                        <div className="item-section3" data-aos="fade-up">
                            <div className="icon">
                                <RiGroupLine size={50} />
                            </div>
                            <div className="text-box">
                                <h3><FormattedMessage id='section3.box1' /></h3>
                                <p><FormattedMessage id='section3.box1p' /></p>
                                <div className="horizontal-line"></div>
                            </div>
                        </div>
                        <div className="item-section3" data-aos="fade-up">
                            <div className="icon">
                                <GiFlexibleLamp size={50} />
                            </div>
                            <div className="text-box">
                                <h3><FormattedMessage id='section3.box2' /></h3>
                                <p><FormattedMessage id='section3.box2p' /></p>
                                <div className="horizontal-line"></div>
                            </div>
                        </div>
                        <div className="item-section3" data-aos="fade-up">
                            <div className="icon">
                                <BsPen size={50} />
                            </div>
                            <div className="text-box">
                                <h3><FormattedMessage id='section3.box3' /></h3>
                                <p><FormattedMessage id='section3.box3p' /></p>
                                <div className="horizontal-line"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </IntlProvider>
    );
}

export default Section3;
