import React from 'react'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import './contact3.scss'

const containerStyle = {
    width: '100%',
    height: '400px',
    marginBottom: '5rem'
};

const center = {
    lat: -3.745,
    lng: -38.523
};




const Contact3 = () => {

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "YOUR_API_KEY"
    })

    const [map, setMap] = React.useState(null)

    const onLoad = React.useCallback(function callback(map) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);

        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])



    return isLoaded ? (
        <div className="maps">
            <iframe
                title="Google Map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d490.8408659319652!2d10.583880091240434!3d52.961703076643175!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47ae2c8a8f51a3f3%3A0x43550e368c97fc5c!2sPlatenmeisterstra%C3%9Fe%2027%2C%2029525%20Uelzen%2C%20Deutschland!5e0!3m2!1sde!2s!4v1677334854080!5m2!1sde!2s"
                width="100%"
                height="500"
                style={{ border: 0 }}
                allowFullScreen={true}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
            />
        </div>

    ) : <></>
}

export default Contact3