import React, { createContext, useState } from 'react';
import messages from '../component/en.json'; // default to English
import deMessages from '../component/de.json'; // German translations

export const TranslateContext = createContext();

const TranslateProvider = ({ children }) => {
  const lang = localStorage.getItem('lang') || 'de';
  const [locale, setLocale] = useState(lang);
  const [messagesForLocale, setMessagesForLocale] = useState(lang === 'de' ? deMessages : messages);

  const handleChangeLocale = (newLocale) => {
    setLocale(newLocale);
    setMessagesForLocale(newLocale === 'de' ? deMessages : messages);
    localStorage.setItem('lang', newLocale);
  };

  return (
    <TranslateContext.Provider value={{ locale, messages: messagesForLocale, changeLocale: handleChangeLocale }}>
      {children}
    </TranslateContext.Provider>
  );
};

export default TranslateProvider;
