import React, { useEffect } from 'react'
import './impressum.scss'
import Impresum1 from '../../component/section1-impressum/Impresum1'
import Impresum2 from '../../component/section2-impressum/Impresum2'

const Impressum = () => {
    useEffect(() => {
        document.title = "Impressum - Malerbetrieb Uelzen - André Mehmel & Fidan Iseni GbR";
    }, []);
    return (
        <div className="">
            <Impresum1 />
            <Impresum2 />
        </div>


    )
}

export default Impressum