import React, { useContext, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './navbar.scss';
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';
import { TranslateContext } from '../../context/TranslateContext';
import $ from 'jquery';



const Navbar = () => {
    const [isOpen, setOpen] = React.useState(false);
    const { messages, changeLocale } = useContext(TranslateContext);
    const menuRef = useRef();



    const handleClick = () => {
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen(!isOpen);
    };

    // useEffect(() => {
    //     // Use jQuery to animate the navbar
    //     if (isOpen) {
    //         $('.navbar-nav').animate({ right: 0 }, 300);
    //     } else {
    //         $('.navbar-nav').animate({ right: '-100%' }, 300);
    //     }
    // }, [isOpen]);

    useEffect(() => {
        if (isOpen) {
            $(menuRef.current).css({ display: 'block', right: '-100%' });
            $(menuRef.current).animate({ right: '0' }, 300);
            $(menuRef.current).css({ overflow: 'hidden' });
            document.body.style.overflow = 'hidden';
        } else {
            $(menuRef.current).animate({ right: '-100%' }, 300, function () {
                $(menuRef.current).css({ display: 'none' });
                document.body.style.overflow = 'auto';
            });
        }
    }, [isOpen]);


    useEffect(() => {
        const bodyElement = document.querySelector('body');

        if (isOpen) {
            bodyElement.classList.add('no-scroll');
        } else {
            bodyElement.classList.remove('no-scroll');
        }
    }, [isOpen]);

    return (
        <nav className={`navbar ${isOpen ? 'show-nav' : ''}`}>
            <div style={{ zIndex: '325325325325325' }} className="icon" onClick={handleToggle}>
                {!isOpen ? <AiOutlineMenu size={30} /> : <AiOutlineClose size={30} />}
            </div>
            <ul ref={menuRef} className={`navbar-nav ${isOpen ? 'show-nav' : ''}`}>
                <li className="nav-item">
                    <Link onClick={handleClick} className="nav-link" to="/">
                        {messages.home}
                    </Link>
                </li>
                <li className="nav-item">
                    <Link onClick={handleClick} className="nav-link" to="/about">
                        {messages.about}
                    </Link>
                </li>
                <li className="nav-item">
                    <Link onClick={handleClick} className="nav-link" to="/services">
                        {messages.services}
                    </Link>
                </li>
                <li className="nav-item">
                    <Link onClick={handleClick} className="nav-link" to="/portfolio">
                        {messages.portfolio}
                    </Link>
                </li>
                <li className="nav-item">
                    <Link onClick={handleClick} className="nav-link" to="/contact">
                        {messages.contact}
                    </Link>
                </li>
                <div className='btn-nav'>
                    <button onClick={() => changeLocale('de')}>Deutsch</button>
                    <button onClick={() => changeLocale('en')}>English</button>
                </div>
            </ul>
        </nav>
    );
};

export default Navbar;
