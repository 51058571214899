import React, { useContext } from 'react';
import logo from '../../images/bildmarke_94x78px.png';
import './footer.scss';
import { Link } from 'react-router-dom';
import { FormattedMessage, IntlProvider } from 'react-intl';
import deMessages from '../de.json';
import enMessages from '../en.json';
import { TranslateContext } from '../../context/TranslateContext';
import ScrollToTop from "react-scroll-to-top";

const messages = {
    'de': deMessages,
    'en': enMessages
};

const Footer = () => {
    const { locale } = useContext(TranslateContext);

    function handleClick() {
        document.documentElement.scrollTop = 0;
    }


    return (
        <>
            <ScrollToTop smooth color='white' />
            <IntlProvider locale={locale} messages={messages[locale]}>
                <div className='footer'>
                    <div className="container-footer">
                        <Link to='/'>
                            <div className="logo" >

                                <img src={logo} alt="" />

                            </div>
                        </Link>
                        <div className="about-footer">
                            <h1>
                                <FormattedMessage id="company_name" />
                            </h1>
                            <p>
                                <FormattedMessage id="company_description" />
                            </p>
                            {/* <Link style={{ textDecoration: 'none', color: 'white' }} to="/about">
                            <FormattedMessage id="about_us" />
                        </Link> */}

                            <Link style={{ textDecoration: 'none', color: 'white' }} onClick={handleClick} to="/about"><span>- <FormattedMessage id="about_us" /></span></Link>
                            <div className="social-footer">
                                <span style={{ fontWeight: 'normal', fontSize: '12px' }}>
                                    <FormattedMessage id="copyright" /> © 2023 ·
                                </span>
                                <span style={{ fontWeight: 'normal', fontSize: '12px' }}>
                                    <FormattedMessage id="twitter" />
                                </span>
                                <span style={{ fontWeight: 'normal', fontSize: '12px' }}>
                                    <FormattedMessage id="facebook" />
                                </span>
                                <span style={{ fontWeight: 'normal', fontSize: '12px' }}>
                                    <Link className='link' to='/contact' onClick={handleClick}>
                                        <FormattedMessage id="contact" />
                                    </Link >
                                </span>

                                <span style={{ fontWeight: 'normal', fontSize: '12px' }}>
                                    <Link onClick={handleClick} to='/impressum' className='link' >  <FormattedMessage id="imprint" /></Link>
                                </span>

                            </div>
                        </div>
                        <div className="newsletter-footer">
                            <h1>
                                <FormattedMessage id="newsletter" />
                            </h1>
                            <p>
                                <FormattedMessage id="newsletter_description" />
                            </p>
                            <input type="text" placeholder='Email' />
                            <button>
                                <FormattedMessage id="subscribe" />
                            </button>
                        </div>
                    </div>
                </div >
            </IntlProvider >
        </>
    );
};

export default Footer;
