import React, { useEffect } from 'react'
import Section1 from '../../component/section1-about/Section1'
import Section2 from '../../component/section2-about/Section2'
import Section3 from '../../component/section3-about/Section3'
import Section4 from '../../component/section4-about/Section4'
import Section6 from '../../component/section6/Section6'





const About = () => {
    useEffect(() => {
        document.title = "Über uns - Malerbetrieb Uelzen - André Mehmel & Fidan Iseni GbR";
    }, []);

    return (
        <div className='about'>
            <Section1 />
            <Section2 />
            <Section3 />
            <Section4 />
            <Section6 />
        </div>
    );
};

export default About;
