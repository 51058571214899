import './portfolio3.scss'
import { AiOutlineCloudUpload } from 'react-icons/ai'
import { IoPersonOutline } from 'react-icons/io5'
import { Pagination, Autoplay } from "swiper";

import React, { useRef, useState, useContext } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import img1 from '../../images/restorant/restorant1.jpg'
import img2 from '../../images/restorant/restorant2.jpg'
import img3 from '../../images/restorant/restorant3.jpg'
import img4 from '../../images/restorant/restorant4.jpg'
import img5 from '../../images/restorant/restorant5.jpg'
import img6 from '../../images/restorant/restorant6.jpg'
import img7 from '../../images/restorant/restorant7.jpg'
import img8 from '../../images/restorant/restorant8.jpg'
import img9 from '../../images/restorant/restorant9.jpg'
import img10 from '../../images/restorant/restorant10.jpg'
import img11 from '../../images/restorant/restorant11.jpg'
import { TranslateContext } from '../../context/TranslateContext';

import "swiper/css";
import "swiper/css/pagination";

import { FormattedMessage, IntlProvider } from 'react-intl';
import deMessages from '../de.json';
import enMessages from '../en.json';


const messages = {
    'de': deMessages,
    'en': enMessages
};




const Portfolio3 = () => {

    const progressCircle = useRef(null);
    const progressContent = useRef(null);
    const onAutoplayTimeLeft = (s, time, progress) => {
        progressCircle.current.style.setProperty('--progress', 1 - progress);
        progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
    };
    const { locale } = useContext(TranslateContext);

    return (
        <IntlProvider locale={locale} messages={messages[locale]}>

            <div className='portfolio3'>
                <div className="portfolio3-container">
                    <div className="text" data-aos="fade-up">
                        <span style={{ letterSpacing: '5px', color: 'rgb(180, 71, 71)', fontWeight: 'bolder' }}>.03</span>
                        <h1>- <FormattedMessage id='portfolio.section3.h' /></h1>
                        {/* <p>TOP RATED PROJECTS</p> */}
                        {/* <span>Here we would like to give you an impression of our reference projects and let our customers have their say:</span> */}
                    </div>
                    <div className="portfolio3-text">
                        <div className="item" data-aos="fade-up">
                            <p><FormattedMessage id='portfolio.section3.box1p' /></p>

                        </div>
                        <div className="item" data-aos="fade-up">
                            <h2> <AiOutlineCloudUpload size={30} /> <FormattedMessage id='portfolio.section3.box1status' /> </h2>
                            <span><FormattedMessage id='portfolio.section3.box1completed' /></span>
                        </div>
                        <div className="item" data-aos="fade-up">
                            <h2> <IoPersonOutline size={30} /> <FormattedMessage id='portfolio.section3.box1client' /> </h2>
                            <span><FormattedMessage id='portfolio.section3.box1emri' /></span>
                        </div>
                    </div>

                    <Swiper data-aos="fade-up"
                        autoplay={{
                            delay: 4500,
                            disableOnInteraction: false,
                        }}
                        loop={true}
                        pagination={{
                            dynamicBullets: true,

                        }}
                        modules={[Autoplay, Pagination]}
                        onAutoplayTimeLeft={onAutoplayTimeLeft}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <div className="img-swiper1">
                                <img src={img1} alt="" />

                            </div>

                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="img-swiper1">
                                <img src={img2} alt="" />

                            </div>



                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="img-swiper1">
                                <img src={img3} alt="" />

                            </div>



                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="img-swiper1">
                                <img src={img4} alt="" />

                            </div>



                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="img-swiper1">
                                <img src={img5} alt="" />

                            </div>



                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="img-swiper1">
                                <img src={img6} alt="" />

                            </div>



                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="img-swiper1">
                                <img src={img7} alt="" />

                            </div>



                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="img-swiper1">
                                <img src={img8} alt="" />

                            </div>



                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="img-swiper1">
                                <img src={img9} alt="" />

                            </div>



                        </SwiperSlide>
                        <SwiperSlide className='swiper'>
                            <div className="img-swiper1">
                                <img src={img10} alt="" />

                            </div>



                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="img-swiper1">
                                <img src={img11} alt="" />

                            </div>



                        </SwiperSlide>
                        <div className="autoplay-progress" slot="container-end">
                            <svg viewBox="0 0 48 48" ref={progressCircle}>
                                <circle cx="24" cy="24" r="20"></circle>
                            </svg>
                            <span ref={progressContent}></span>
                        </div>

                    </Swiper >

                </div>
            </div>
        </IntlProvider>
    )
}

export default Portfolio3