import Reac, { useContext } from 'react'
import './section4.scss'
import FidanIseni from '../../images/Fidan-Iseni-Malerbetrieb-300x300.jpeg'
import AndréMehmel from '../../images/Andre-mehmel-malerbetrieb-300x300.jpg'
import { FormattedMessage, IntlProvider } from 'react-intl';
import { TranslateContext } from '../../context/TranslateContext';

import deMessages from '../de.json';
import enMessages from '../en.json';


const messages = {
    'de': deMessages,
    'en': enMessages
};



const Section4 = () => {
    const { locale } = useContext(TranslateContext);

    return (
        <IntlProvider locale={locale} messages={messages[locale]}>
            <div className='section4-about'>
                <div className="section4-container">
                    <div className="text-section4" data-aos="fade-up">
                        <span style={{ letterSpacing: '5px', color: 'rgb(180, 71, 71)', fontWeight: 'bolder' }}>.03</span>
                        <h1>- <FormattedMessage id='h1' /></h1>
                        <p> <FormattedMessage id='p' /></p>
                    </div>
                    <div className="box-section4">
                        <div className="item-section4" data-aos="fade-up">
                            <div className="images-team">
                                <img src={FidanIseni} alt="" />
                            </div>
                            <div className="item-text-team">
                                <h1>Fidan Iseni</h1>
                                <p style={{ fontSize: '12px', color: 'black' }}><FormattedMessage id='team_fidan' /></p>
                                <p style={{ fontSize: '14px', }}><FormattedMessage id='trained_fidan' /></p>
                                <span><FormattedMessage id='exp_fidan' /></span>
                                <div className="horizontal-line"></div>

                            </div>

                        </div>
                        <div className="item-section4" data-aos="fade-up">
                            <div className="images-team">
                                <img src={AndréMehmel} alt="" />
                            </div>
                            <div className="item-text-team">
                                <h1>André Mehmel </h1>
                                <p style={{ fontSize: '12px', color: 'black' }}><FormattedMessage id='team_andre' /></p>
                                <p style={{ fontSize: '14px', }}><FormattedMessage id='trained-andre' /></p>
                                <span>
                                    <FormattedMessage id='exp-andre' />
                                </span>
                                <div className="horizontal-line"></div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </IntlProvider>
    )
}

export default Section4