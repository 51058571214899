import React, { useContext } from 'react';
import './portfolio2.scss'
import { Link } from 'react-router-dom'
import img1 from '../../images/projekti1.jpg'
import img2 from '../../images/projekti2.jpg'
import img3 from '../../images/projekti3.jpg'
import img4 from '../../images/projekti4.jpg'
import img5 from '../../images/projekti5.jpg'
import img6 from '../../images/projekti6.jpg'
import { TranslateContext } from '../../context/TranslateContext';

import { FormattedMessage, IntlProvider } from 'react-intl';
import deMessages from '../de.json';
import enMessages from '../en.json';


const messages = {
    'de': deMessages,
    'en': enMessages
};



const Portfolio2 = () => {

    const { locale } = useContext(TranslateContext);




    return (
        <IntlProvider locale={locale} messages={messages[locale]}>
            <div className='portfolio2'>
                <div className="portfolio2-container">
                    <div className="text" data-aos="fade-up">
                        <span style={{ letterSpacing: '5px', color: 'rgb(180, 71, 71)', fontWeight: 'bolder' }}>.01</span>
                        <h1>- <FormattedMessage id='portfolio.section2.h' /></h1>
                        <p><FormattedMessage id='portfolio.section2.p' /></p>
                        <span><FormattedMessage id='portfolio.section2.span' /></span>

                    </div>

                    {/* <div className="box-p">
                    <div className="portfolio2-item">
                        <Link to='/portfolio/projekti1'>
                            <img src={img1} alt="" />
                        </Link>
                    </div>
                    <div className="portfolio2-item">
                        <Link to='/portfolio/projekti2'>
                            <img src={img2} alt="" />
                        </Link>
                    </div>

                    <div className="portfolio2-item">
                        <Link to='/portfolio/projekti3'>
                            <img src={img3} alt="" />
                        </Link>
                    </div>
                    <div className="portfolio2-item">
                        <Link to='/portfolio/projekti4'>
                            <img src={img4} alt="" />
                        </Link>
                    </div>
                    <div className="portfolio2-item">
                        <Link to='/portfolio/projekti5'>
                            <img src={img5} alt="" />
                        </Link>
                    </div>
                    <div className="portfolio2-item">
                        <Link to='/portfolio/projekti6'>
                            <img src={img6} alt="" />
                        </Link>
                    </div>

                </div> */}

                </div>
            </div>
        </IntlProvider >
    )
}

export default Portfolio2