import React, { useContext } from 'react'
import './section6.scss'
import { FormattedMessage, IntlProvider } from 'react-intl';
import { Link } from 'react-router-dom';
import deMessages from '../de.json';
import enMessages from '../en.json';
import { TranslateContext } from '../../context/TranslateContext';


const messages = {
    'de': deMessages,
    'en': enMessages
};


const Section6 = () => {

    function handleClick() {
        document.documentElement.scrollTop = 0;
    }



    const { locale } = useContext(TranslateContext);

    return (
        <IntlProvider locale={locale} messages={messages[locale]}>

            <div className='section6'>
                <div className="container-section6">
                    <div className="text-section6" data-aos="fade-up">
                        <p><FormattedMessage id='section6_p' /></p>
                        <h1><FormattedMessage id='section6_h1' /></h1>
                    </div>
                    <div className="btn" data-aos="fade-up">
                        <Link to="/portfolio" onClick={handleClick}><button>portfolio</button></Link>

                    </div>



                </div>
            </div>
        </IntlProvider>
    )
}

export default Section6