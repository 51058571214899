import React, { useContext } from 'react'
import './services3.scss'
import services3 from '../../images/services-images.png'
import { FormattedMessage, IntlProvider } from 'react-intl';
import { Link } from 'react-router-dom';
import deMessages from '../de.json';
import enMessages from '../en.json';
import { TranslateContext } from '../../context/TranslateContext';


const messages = {
    'de': deMessages,
    'en': enMessages
};




const Services3 = () => {
    const { locale } = useContext(TranslateContext);

    function handleClick() {
        document.documentElement.scrollTop = 0;
    }


    return (
        <IntlProvider locale={locale} messages={messages[locale]}>

            <div className='services3'>
                <div className="services3-container">
                    <div className="services3-text" data-aos="fade-up">
                        <span>uelzen</span>
                        <h3><FormattedMessage id='section3.h3' /></h3>
                        <p><FormattedMessage id='section3.p' /></p>
                        <div className="btn">
                            <Link className='link' to='/portfolio' onClick={handleClick}><h2>- <FormattedMessage id='section3.btn' /></h2></Link>

                        </div>
                    </div>
                    <div className="services3-images" data-aos="fade-up">
                        <img src={services3} alt="" />
                    </div>
                </div>
            </div>
        </IntlProvider>
    )
}

export default Services3