import React, { useContext, useRef, useState } from 'react';
import './contact4.scss';
import { FormattedMessage, IntlProvider } from 'react-intl';
import deMessages from '../de.json';
import enMessages from '../en.json';
import { TranslateContext } from '../../context/TranslateContext';
import axios from 'axios';

const messages = {
    'de': deMessages,
    'en': enMessages
};

const Result = () => {
    const { locale } = useContext(TranslateContext);

    return (
        <IntlProvider locale={locale} messages={messages[locale]}>
            <p><FormattedMessage id='result' /></p>
        </IntlProvider>
    );
};

const Contact4 = () => {
    const [result, showResult] = useState(false);
    const [loading, setLoading] = useState(false);
    const form = useRef();

    const sendEmail = async (e) => {
        e.preventDefault();
        setLoading(true);
        const formData = new FormData(form.current);
        const data = Object.fromEntries(formData.entries());

        try {
            const response = await axios.post('https://api-germanyproject-v7.onrender.com/send-email', data);
            console.log('Email sent successfully!', response.data);
            e.target.reset();
            showResult(true);
        } catch (error) {
            console.error('Error sending email:', error);
        }
        setLoading(false);
    };

    const { locale } = useContext(TranslateContext);

    return (
        <IntlProvider locale={locale} messages={messages[locale]}>
            <div className='contact4'>
                <div className="contact4-container">
                    <form ref={form} onSubmit={sendEmail}>
                        <h1 data-aos="fade-up"><FormattedMessage id='contact.section4.h1' />:</h1>
                        <div className="label">
                            <label htmlFor=""><FormattedMessage id='contact.section4.label1' /></label>
                            <input type="text" name='user_name' required />
                        </div>
                        <div className="label">
                            <label htmlFor=""><FormattedMessage id='contact.section4.label2' /></label>
                            <input type="email" name='email' required />
                        </div>
                        <div className="label">
                            <label htmlFor=""><FormattedMessage id='contact.section4.label3' /></label>
                            <textarea required name="message" cols="30" rows="10" />
                        </div>
                        <button disabled={loading} className={loading ? 'loading' : ''}>
                            {loading ? 'SENDING...' : <FormattedMessage id='contact.section4.btn' />}
                        </button>
                        <div className="row" style={{ alignItems: 'center', textAlign: "center" }}>
                            {result ? <Result /> : null}
                        </div>
                    </form>
                </div>
            </div>
        </IntlProvider>
    );
};

export default Contact4;
