import React, { useEffect, useContext } from 'react'
import Portfolio1 from '../../component/section1-portfolio/Portfolio1';
import Portfolio2 from '../../component/section2-portfolio/Portfolio2';
import Portfolio3 from '../../component/section3-portofolio/Portfolio3';
import Portfolio4 from '../../component/section4-portfolio/Portfolio4';
import Portfolio5 from '../../component/section5-portfolio/Portfolio5';
import Portfolio6 from '../../component/section6-portfolio/Portfolio6';
import Portfolio7 from '../../component/section7-portfolio/Portfolio7';
import { FormattedMessage, IntlProvider } from 'react-intl';
import deMessages from '../../component/de.json';
import enMessages from '../../component/en.json';
import { TranslateContext } from '../../context/TranslateContext';


const messages = {
    'de': deMessages,
    'en': enMessages
};

const Portfolio = () => {

    const { locale } = useContext(TranslateContext);


    useEffect(() => {

        document.title = "Portfolio - Malerbetrieb Uelzen - André Mehmel & Fidan Iseni GbR";
    }, []);
    return (
        <div>
            <Portfolio1 />
            <Portfolio2 />
            <Portfolio7 />
            <Portfolio3 />
            <Portfolio4 />
            <Portfolio5 />
            <Portfolio6 />
            <IntlProvider locale={locale} messages={messages[locale]}>
            </IntlProvider>
        </div>
    )
}

export default Portfolio