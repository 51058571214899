import React, { useContext } from 'react'
import './section3.scss'
import banner from '../../images/about1.jpeg'

import { FormattedMessage, IntlProvider } from 'react-intl';
import deMessages from '../de.json';
import enMessages from '../en.json';
import { TranslateContext } from '../../context/TranslateContext';


const messages = {
    'de': deMessages,
    'en': enMessages
};



const Section3 = () => {
    const { locale } = useContext(TranslateContext);

    return (
        <IntlProvider locale={locale} messages={messages[locale]}>
            <div className='section3'>
                <div className="container-section3">
                    <div className="text" data-aos="fade-up">
                        <span >UELZEN</span>
                        <h1> <FormattedMessage id="section3_specialist" /></h1>
                        <p>
                            <FormattedMessage id='section3_established' />
                        </p>

                        <span style={{ color: 'white', marginTop: '20px', letterSpacing: '2px' }} >- <FormattedMessage id='section3_about' /> </span>
                    </div>

                    <div className="images-section3" data-aos="fade-up">
                        <img src={banner} alt="" />
                    </div>
                </div>
            </div>
        </IntlProvider>
    )
}

export default Section3