import React, { useContext } from 'react';
import './portfolio.scss'
import img1 from '../../images/pexels-photo-1797418.webp'
import logo from '../../images/bildmarke_94x78px.png'
import { FormattedMessage, IntlProvider } from 'react-intl';
import deMessages from '../de.json';
import enMessages from '../en.json';
import { TranslateContext } from '../../context/TranslateContext';
import { Link } from 'react-router-dom';

const messages = {
    'de': deMessages,
    'en': enMessages
};



const Portfolio1 = () => {
    const { locale } = useContext(TranslateContext);

    return (
        <IntlProvider locale={locale} messages={messages[locale]}>

            <div className='section1-portfolio'>
                <div className="images-container">
                    <div className="images-contact">
                        <img src={img1} alt="" />
                    </div>
                    <div className="container-section-portfolio">


                        <div className="logo" data-aos="fade-up">

                            <img src={logo} alt="" />

                        </div>

                        <div className="vertical-line1"></div>

                        <div className="text" data-aos="fade-up">
                            <h1><FormattedMessage id='portfolio.section1.h' /> </h1>
                            {/* <p>wir sorgen fur ihren perfekten anstrich</p> */}
                        </div>

                        <div className="container-line">
                            <div className="vertical-line"></div>
                            <div className="horizontal-line"></div>
                        </div>



                    </div>
                </div>


            </div>
        </IntlProvider>
    )
}

export default Portfolio1