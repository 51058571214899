import React, { useEffect } from 'react'
import Service1 from '../../component/section1-services/Service1'
import Service2 from '../../component/section2-services/Service2'
import Services3 from '../../component/section3-services/Services3'
import Services4 from '../../component/section4-services/Services4'

const Services = () => {
    useEffect(() => {
        document.title = "Dienstleistungen - Malerbetrieb Uelzen - André Mehmel & Fidan Iseni GbR";
    }, []);
    return (
        <div>
            <Service1 />
            <Service2 />
            <Services3 />
            <Services4 />
        </div>
    )
}

export default Services