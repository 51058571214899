import React, { useRef, useState, useContext } from 'react'
import './portfolio5.scss'
import { AiOutlineCloudUpload } from 'react-icons/ai'
import { IoPersonOutline } from 'react-icons/io5'

import { Pagination, Autoplay } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

import img1 from '../../images/room2/room1.jpg'
import img2 from '../../images/room2/room2.jpg'
import img3 from '../../images/room2/room3.jpg'

import { FormattedMessage, IntlProvider } from 'react-intl';
import deMessages from '../de.json';
import enMessages from '../en.json';
import { TranslateContext } from '../../context/TranslateContext';


const messages = {
    'de': deMessages,
    'en': enMessages
};

const Portfolio5 = () => {

    const progressCircle = useRef(null);
    const progressContent = useRef(null);
    const onAutoplayTimeLeft = (s, time, progress) => {
        progressCircle.current.style.setProperty('--progress', 1 - progress);
        progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
    };


    const { locale } = useContext(TranslateContext);



    return (
        <IntlProvider locale={locale} messages={messages[locale]}>

            <div className='portfolio5'>
                <div className="portfolio5-container">
                    <div className="text" data-aos="fade-up">
                        <span style={{ letterSpacing: '5px', color: 'rgb(180, 71, 71)', fontWeight: 'bolder' }}>.05</span>
                        <h1>- <FormattedMessage id='portfolio.section5.h' /></h1>
                        {/* <p>TOP RATED PROJECTS</p> */}
                        {/* <span>Here we would like to give you an impression of our reference projects and let our customers have their say:</span> */}
                    </div>
                    <div className="portfolio5-text">
                        <div className="item" data-aos="fade-up">
                            <p><FormattedMessage id='portfolio.section5.box1p' /></p>

                        </div>
                        <div className="item" data-aos="fade-up">
                            <h2> <AiOutlineCloudUpload size={30} /> <FormattedMessage id='portfolio.section5.box1status' /> </h2>
                            <span><FormattedMessage id='portfolio.section5.box1completed' /></span>
                        </div>
                        <div className="item" data-aos="fade-up">
                            <h2> <IoPersonOutline size={30} /> <FormattedMessage id='portfolio.section5.box1client' /> </h2>
                            <span><FormattedMessage id='portfolio.section5.box1emri' /></span>
                        </div>
                    </div>

                    <Swiper data-aos="fade-up"
                        autoplay={{
                            delay: 4500,
                            disableOnInteraction: false,
                        }}
                        loop={true}
                        pagination={{
                            dynamicBullets: true,

                        }}
                        modules={[Autoplay, Pagination]}
                        onAutoplayTimeLeft={onAutoplayTimeLeft}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <div className="img-swiper1">
                                <img src={img1} alt="" />

                            </div>

                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="img-swiper1">
                                <img src={img2} alt="" />

                            </div>



                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="img-swiper1">
                                <img src={img3} alt="" />

                            </div>



                        </SwiperSlide>

                        <div className="autoplay-progress" slot="container-end">
                            <svg viewBox="0 0 48 48" ref={progressCircle}>
                                <circle cx="24" cy="24" r="20"></circle>
                            </svg>
                            <span ref={progressContent}></span>
                        </div>

                    </Swiper >
                </div>
            </div>
        </IntlProvider>
    )
}

export default Portfolio5