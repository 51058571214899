import React, { useContext } from 'react'
import './section2.scss'
import images1 from '../../images/studio1.jpg'
import images2 from '../../images/stuido 2.webp'
import images3 from '../../images/studio.jpeg'
import { TranslateContext } from '../../context/TranslateContext';
import { FormattedMessage, IntlProvider } from 'react-intl';
import deMessages from '../de.json';
import enMessages from '../en.json';


const messages = {
    'de': deMessages,
    'en': enMessages
};


const Section2 = () => {
    const { locale } = useContext(TranslateContext);

    return (
        <>
            <IntlProvider locale={locale} messages={messages[locale]}>
                <div className='section2'>
                    <div className="container">
                        <div className="text">
                            <span data-aos="fade-up" style={{ letterSpacing: '5px', color: 'rgb(180, 71, 71)', fontWeight: 'bolder' }}>.01</span>
                            <h1 data-aos="fade-up">- <FormattedMessage id="section2_title" /></h1>
                            <p data-aos="fade-up"><FormattedMessage id="section2_description" /> <br /></p>
                        </div>
                        <div className="images" data-aos="fade-up">
                            <div className="image--wrapper">
                                <img src={images1} alt="" />
                                <h4></h4>
                            </div>
                            <div data-aos="fade-up" className="img-container">
                                <img src={images2} alt="" />
                                <img src={images3} alt="" />
                            </div>

                        </div>
                    </div >
                </div >
            </IntlProvider>
        </>
    )
}

export default Section2