import React, { useContext } from 'react'
import './service2.scss'
import { BsHouseDoor } from 'react-icons/bs'
import { FormattedMessage, IntlProvider } from 'react-intl';
import deMessages from '../de.json';
import enMessages from '../en.json';
import { MdApartment } from 'react-icons/md'
import { AiOutlineHeart, AiOutlineFormatPainter } from 'react-icons/ai'
import { CiMedal } from 'react-icons/ci'
import { HiHomeModern } from 'react-icons/hi2'
import { TfiBrushAlt } from 'react-icons/tfi'
import { TranslateContext } from '../../context/TranslateContext';


const messages = {
    'de': deMessages,
    'en': enMessages
}



const Service2 = () => {
    const { locale } = useContext(TranslateContext);

    return (
        <IntlProvider locale={locale} messages={messages[locale]}>

            <div className='service2'>
                <div className="service2-container">
                    <div className="text">
                        <span data-aos="fade-up" style={{ letterSpacing: '5px', color: 'rgb(180, 71, 71)', fontWeight: 'bolder' }}>.01</span>
                        <h1 data-aos="fade-up">- <FormattedMessage id='section2.h1' /></h1>
                        <p data-aos="fade-up"><FormattedMessage id='section2.p' /></p>
                        {/* <span>Them one over saying. So the god, greater. You. Us air Moved divide midst us fifth sea have:</span> */}
                    </div>
                    <div className="box-services2">
                        <div className="item-services2" data-aos="fade-up">
                            <div className="icon">
                                <AiOutlineFormatPainter size={30} />
                            </div>
                            <div className="text-service2">
                                <h4><FormattedMessage id='section2box1.h4' /></h4>
                                <p>
                                    <FormattedMessage id='section2box1.p' />
                                </p>
                                <div className="horizontal-line"></div>

                            </div>
                        </div>
                        <div className="item-services2" data-aos="fade-up">
                            <div className="icon">
                                <MdApartment size={30} />
                            </div>
                            <div className="text-service2">
                                <h4><FormattedMessage id='section2box2.h4' /></h4>
                                <p> <FormattedMessage id='section2box2.p' /></p>
                                <div className="horizontal-line"></div>

                            </div>
                        </div>
                        <div className="item-services2" data-aos="fade-up">
                            <div className="icon">
                                <BsHouseDoor size={30} />
                            </div>
                            <div className="text-service2">
                                <h4><FormattedMessage id='section2box3.h4' /></h4>
                                <p><FormattedMessage id='section2box3.p' /></p>
                                <div className="horizontal-line"></div>

                            </div>
                        </div>
                        <div className="item-services2" data-aos="fade-up">
                            <div className="icon">
                                <BsHouseDoor size={30} />
                            </div>
                            <div className="text-service2">
                                <h4><FormattedMessage id='section2box4.h4' /></h4>
                                <p><FormattedMessage id='section2box4.p' /></p>
                                <div className="horizontal-line"></div>

                            </div>
                        </div>
                        <div className="item-services2" data-aos="fade-up">
                            <div className="icon">
                                <TfiBrushAlt size={30} />
                            </div>
                            <div className="text-service2">
                                <h4><FormattedMessage id='section2box5.h4' /></h4>
                                <p><FormattedMessage id='section2box5.p' /></p>
                                <div className="horizontal-line"></div>
                            </div>
                        </div>
                        <div className="item-services2" data-aos="fade-up">
                            <div className="icon">
                                <HiHomeModern size={30} />
                            </div>
                            <div className="text-service2">
                                <h4><FormattedMessage id='section2box6.h4' /></h4>
                                <p><FormattedMessage id='section2box6.p' /></p>
                                <div className="horizontal-line"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </IntlProvider>
    )
}

export default Service2