import React, { useEffect, useContext } from 'react'
import Section1 from '../../component/section1/Section1'
import Section2 from '../../component/section2/Section2'
import Section3 from '../../component/section3/Section3'
import Section4 from '../../component/section4/Section4'
import Section5 from '../../component/section5/Section5'
import Section6 from '../../component/section6/Section6'
import { FormattedMessage, IntlProvider } from 'react-intl';
import deMessages from '../../component/de.json';
import enMessages from '../../component/en.json';
import { TranslateContext } from '../../context/TranslateContext';


const messages = {
    'de': deMessages,
    'en': enMessages
};


const Home = () => {

    const { locale } = useContext(TranslateContext);


    useEffect(() => {
        <FormattedMessage id='document_titleHome' />
        document.title = "Malerbetrieb Uelzen - André Mehmel & Fidan Iseni GbR";
    }, []);
    return (
        <div>
            <Section1 />
            <Section2 />
            <Section3 />
            <Section4 />
            {/* <Section5 /> */}
            <Section6 />
        </div>
    )
}

export default Home