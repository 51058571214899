import React from 'react';
import './index.scss';
import App from './App';
import messages from './component/en.json'; // default to English
import TranslateProvider from './context/TranslateContext';
import ReactDOM from 'react-dom';


import AOS from 'aos';

import axios from 'axios'

AOS.init();

ReactDOM.render(
  <React.StrictMode>
    <TranslateProvider>
      <App />
    </TranslateProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
